<template>
  <!-- Edit footer modal -->
  <b-modal
    id="modal-edit-footer"
    :title="$t('footer.title')"
    size="xl"
    modal-class="pb-5"
    no-enforce-focus
    :ok-title="$t('form.actions.save')"
    @ok="handleChangeFooter"
  >
    <ckeditor 
      :editor="editor" 
      v-model="footerText"
    >
    </ckeditor>
  </b-modal>  
</template>



<script>
import { BModal } from 'bootstrap-vue';
import { quillEditor } from 'vue-quill-editor';
import ToastNotificationsMixin from '@core/mixins/toast-notifications/ToastNotificationsMixin';
import * as Editor from '@core/editor';


export default {
  components: {
    BModal,
    quillEditor,
  },
  mixins: [ToastNotificationsMixin],
  props: {
    content: {
      type: String,
      default: '',
      
    },
  },
  data() {
    return {
      footerText: '',
      editor: Editor,
    };
  },
  created() {
    this.contentDescription();
  },
  methods: {
    handleChangeFooter() {
      let footer;
      if (this.footerText.includes('<figure class="image')) {
        if (this.footerText.includes('<figure class="image image_resized image-style-side"')) {
          footer = this.footerText.replace(/><img /g, ' ');
          footer = footer.replace(/<figure class="image image_resized image-style-side"/g, '<img class="float-right"');
          footer = footer.replace(/<\/figure>/g, '</img>');
        } else if (this.footerText.includes('<figure class="image image-style-side"')) {
          footer = this.footerText.replace(/><img /g, ' ');
          footer = footer.replace(/<figure class="image image-style-side"/g, '<img class="float-right"');
          footer = footer.replace(/<\/figure>/g, '</img>');
        } else if (this.footerText.includes('<figure class="image image_resized"')) {
          footer = this.footerText.replace(/><img /g, ' ');
          footer = footer.replace(/<figure class="image /g, '<img class="d-block mx-auto"');
          footer = footer.replace(/<\/figure>/g, '</img>');
        } else {
          footer = this.footerText.replace(/><img /g, ' ');
          footer = footer.replace(/<figure class="image/g, '<img class="d-block mx-auto');
          footer = footer.replace(/<\/figure>/g, '</img>');
        }
      } else {
        footer = this.footerText;
      }
      footer = footer.replace(/www./g, 'https://');
      this.$emit('footer-changed', footer);
      this.notifySuccess(this.$t('footer.messages.success'));
    },
    contentDescription() {
      let footer;
      // this.footerText = this.content.replace(/text/g, 'ql-align');

      if (this.content.includes('<img class="float-right"')) {
        if (this.content.includes('style="width:')) {
          footer = this.content.replace(/<img class="float-right" style=/g, '<figure class="image image_resized image-style-side"><img style=');
          footer = footer.replace(/<\/img>/g, '</figure>');
          this.footerText = footer;
        } else {
          footer = this.content.replace(/<img class="float-right"/g, '<figure class="image image_resized image-style-side"><img ');
          footer = footer.replace(/<\/img>/g, '</figure>');
          this.footerText = footer;
        }
      } else if (this.content.includes('<img class="d-block mx-auto' || '<img class="d-block mx-auto ')) {
        if (this.content.includes('<img class="d-block mx-auto"image_resized"')) {
          footer = this.content.replace(/<img class="d-block mx-auto"image_resized"/g, '<figure class="image image_resized"><img ');
          footer = footer.replace(/<\/img>/g, '</figure>');
          this.footerText = footer;
        } else {
          footer = this.content.replace(/<img class="d-block mx-auto"/g, '<figure class="image"><img ');
          footer = footer.replace(/<\/img>/g, '</figure>');
          this.footerText = footer;
        }
      } else {
        footer = this.content;
        this.footerText = footer;
      }
      this.footerText = footer;
    },
  },
};

</script>

<style lang="scss">
@import "@core/scss/vue/pages/home.scss";
.ck-editor__editable {
    min-height: 300px;
}

:root {
    --ck-z-default: 100 !important;
    --ck-z-modal: calc( var(--ck-z-default) + 999 );
}

</style>
